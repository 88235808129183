import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SeO from "../components/seo"
// import SendInBlueForm from "../components/SendInBlueForm"

import Satisfaction from '../images/assets/guarantee.svg'

import { css } from '@emotion/react'
import Faq from "../components/faq"
import PricingTable from "../components/pricingTable"


const Offres = () => {

    // const webinar_sib_link = "https://721cadc9.sibforms.com/serve/MUIEAGiS-iSFxOiI4F5t1YZNmauSR_p4-Aks1G-7XSRgoLFdj1ve6kj0Fdg95NdrzMiRmnrx0s2yDB85RAHJbc1TvxH8PQ7x0o5hV2NjSNQNghyyD2TtZ4a6vjEhsMCXIuMYrLVbetD7O0YKOXaC0b_woerIBd8DrrtZq8tPl3qOtAtZN7GEduXBPa_lsti5vm2jpRq8seyLr-su"

    // const handleBtnClick = () => {
    //     if ( typeof(window) !== 'undefined' ) {
    //         window.dataLayer = window.dataLayer || []
    //         window.dataLayer.push({
    //             'event': 'product_click'
    //         })
    //         console.log("product_click")
    //     }
    // }

    return (
        <Layout>
            <SeO title="Offres de formation vidéo" />
            <div css={css`
                margin-top: 100px;
                margin-bottom: 100px;
            `} className="constrained">
                <h1>Nos offres et tarifs de formation vidéo</h1>
                <p className="content-intro">Nous proposons plusieurs formules possibles pour nous adapter à votre objectif et à vos ambitions digitales. Vous pouvez changer de plan à tout moment.</p>
            </div>

            {<section style={{"marginTop": "120px", "marginBottom": "80px"}}>
                <h2>Nos <span className="stabilo">3 packages</span> et offres</h2>
                <p>Nous proposons 3 packages différents correspondant à 3 niveaux de support et soutien : chaque package inclut l'accès à tous les cours, qui vous donne un accès illimité aux cours. Puis le package Plus et Entreprise disposent d'un support et accompagnement croissant, par abonnement mensuel additionnel flexible (sans engagement de durée).</p>
                <PricingTable />
            </section>}

            {false && <section>
                <div css={guaranteedSuccess}>
                    <Satisfaction />
                    <div>
                        <h2>Satisfait ou remboursé</h2>
                        <p>Nous vous permettons d'essayer le programme VideoLab sans risque. Ce n'est pas ce que vous attendiez ? Demandez un remboursement intégral et immédiat dans les 7 jours, sans frais.</p>
                    </div>
                </div>

                <div>
                    <h2>Ils nous font confiance</h2>
                    <div style={{"backgroundColor": "lightgrey", "height": "400px"}}></div>
                </div>
            </section>}

            {/* <section className="container" css={webinarBox}>
                <h2><span className="stabilo">Webinaire gratuit</span> : démo du programme</h2>
                <p className="big">Nous vous avons préparé une présentation du programme et des bénéfices à en tirer pour votre activité. Inscrivez-vous gratuitement ci-dessous pour accéder à la présentation, et y découvrir aussi nos premiers conseils sur l'utilisation de la vidéo pour booster votre activité.</p>
                <div className="doodle" style={{color: "white"}}>Découvrez le potentiel de Video-Lab pour votre activité <span role="img" aria-label="regarder en dessous">👇</span></div>
                <SendInBlueForm 
                form_title="webinar"
                title="Voir le webinaire"
                src={webinar_sib_link}
                />
            </section> */}


            <Faq />
        </Layout>
    )
}

export default Offres


const guaranteedSuccess = css`
    margin-top: 88px;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
        width: 200px;
        margin-right: 20px;
    }

    div {
        h2 {
            margin-top: 0;
            font-size: 2rem;
        }

        p {
            margin-bottom: 0;
        }
    }
`


// const webinarBox = css`
//   margin: 100px auto 40px;
//   background-color: ${colors.black};
//   padding: 40px 50px 30px;
//   border-radius: 10px;

//   h2 {
//     color: white;
//     margin: 0 0 10px;

//     span {
//       color: black;
//     }
//   }

//   p {
//     color: white;
//     margin: 0 0 20px;
//   }
// `
